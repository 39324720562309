import User from '@/shared/modules/user/user'
import { AuthStateInterface } from '@/shared/store/auth/auth.state'

export enum AuthGetters {
  isLogged = 'AUTH/IS_LOGGED',
  getUser = 'AUTH/GET_USER',
  getLoading = 'AUTH/GET_LOADING',
}

export default {
  [AuthGetters.isLogged]: (state: AuthStateInterface): boolean => !! state.user,
  [AuthGetters.getUser]: (state: AuthStateInterface): User | null => state.user,
  [AuthGetters.getLoading]: (state: AuthStateInterface): boolean => state.loading,
}
