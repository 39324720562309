import state from './global.state'
import getters from './global.getters'
import mutations from './global.mutations'
import actions from './global.actions'

export default {
  state,
  getters,
  mutations,
  actions,
}
