const authKey = 'authToken'

const getToken = (): null | string => localStorage.getItem(authKey)
const setToken = (token: string) => localStorage.setItem(authKey, token)
const clearToken = (): void => localStorage.removeItem(authKey)

const tokenHelper = {
  getToken,
  setToken,
  clearToken,
}

export default tokenHelper
