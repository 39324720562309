import { AuthStateInterface } from '@/shared/store/auth/auth.state'
import User from '@/shared/modules/user/user'

export enum AuthMutations {
  reset = 'AUTH/RESET',
  setUser = 'AUTH/SET_USER',
  setLoading = 'AUTH/SET_LOADING',
}

export default {
  [AuthMutations.setUser]: (state: AuthStateInterface, payload: User): void => {
    state.user = payload
  },
  [AuthMutations.reset]: (state: AuthStateInterface): void => {
    state.user = null
  },
  [AuthMutations.setLoading]: (state: AuthStateInterface, loading: boolean): void => {
    state.loading = loading
  }
}
