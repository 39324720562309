import User from '@/shared/modules/user/user'

export interface AuthStateInterface {
  user: User | null
  loading: boolean
}

export default (): AuthStateInterface => ({
  user: null,
  loading: false,
})
