import GlobalStateInterface from '@/shared/store/global/global.interface'
import { DialogInterface } from '@/shared/interfaces/dialog.interface'
import globalState from './global.state'
import SnackbarInterface from '@/shared/interfaces/snackbar.interface'

export enum GlobalMutations {
  setLoading = 'GLOBAL/SET_LOADING',
  setDialog = 'GLOBAL/SET_DIALOG',
  resetDialog = 'GLOBAL/RESET_DIALOG',
  setSnackbar = 'GLOBAL/SET_SNACKBAR',
}

export default {
  [GlobalMutations.setLoading]: (state: GlobalStateInterface, payload: boolean): void => {
    state.loading = payload
  },
  [GlobalMutations.setDialog]: (state: GlobalStateInterface, payload: DialogInterface): void => {
    state.dialog = payload
  },
  [GlobalMutations.resetDialog]: (state: GlobalStateInterface): void => {
    state.dialog = globalState().dialog
  },
  [GlobalMutations.setSnackbar]: (state: GlobalStateInterface, payload: SnackbarInterface | null): void => {
    state.snackbar = payload
  },
}
