import Vue from 'vue'
import App from '@/App.vue'
import router from '@/shared/router'
import store from '@/shared/store'
import vuetify from '@/shared/plugins/vuetify'
import '@/assets/scss/app.scss'
import InlineSvg from 'vue-inline-svg'
import VueClipboard from 'vue-clipboard2'

Vue.component('inline-svg', InlineSvg);

Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
