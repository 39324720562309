import { Route } from 'vue-router'
import tokenHelper from '@/shared/helpers/token.helper'
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { AuthActions } from '@/shared/store/auth/auth.actions'

const beforeEach = async (to: Route, from: Route, next: any) => {
  const token: string | null = tokenHelper.getToken()
  const isLogged: boolean = store.getters[AuthGetters.isLogged]

  if (token && ! isLogged) {
    await store.dispatch(AuthActions.me)
  }

  return next()
}

export default beforeEach
