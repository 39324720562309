import GlobalStateInterface from '@/shared/store/global/global.interface'

export default (): GlobalStateInterface => ({
  loading: false,
  dialog: {
    show: false,
    component: null,
    maxWidth: 350,
    persistent: false,
  },
  snackbar: null,
})
