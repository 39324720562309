import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import tokenHelper from '@/shared/helpers/token.helper'
import router, { Routes } from '@/shared/router'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'
import store from './store'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://rating-api.insly.app/api'
})

http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = tokenHelper.getToken()
  if (token) config.headers.Authorization = `Bearer ${ token }`

  config.headers['Content-Type'] = 'application/json'

  return config
})

http.interceptors.response.use((response: AxiosResponse) => {
  return response
}, (error: AxiosError<any>) => {
  if (error.response?.status === 401) {
    tokenHelper.clearToken()
    router.replace({ name: Routes.login }).catch(() => {})
  }

  if (error.response?.status === 500) {
    store.dispatch(GlobalActions.showSnackbar, {
      type: SnackBarType.error,
      message: 'Something wrong happened',
    })
  }

  throw error
})

export default http
