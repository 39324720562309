import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum RuleType {
  note = 'note',
  refer = 'refer',
  decline = 'decline',
}

export const ruleTypes: SelectItem[] = [
  new SelectItem().setValue(RuleType.note).setTitle('Note'),
  new SelectItem().setValue(RuleType.refer).setTitle('Refer'),
  new SelectItem().setValue(RuleType.decline).setTitle('Decline'),
]
