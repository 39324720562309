import GlobalStateInterface from '@/shared/store/global/global.interface'
import { DialogInterface } from '@/shared/interfaces/dialog.interface'
import SnackbarInterface from '@/shared/interfaces/snackbar.interface'

export enum GlobalGetters {
  getLoading = 'GLOBAL/GET_LOADING',
  getDialog = 'GLOBAL/GET_DIALOG',
  getSnackbar = 'GLOBAL/GET_SNACKBAR',
}

export default {
  [GlobalGetters.getLoading]: (state: GlobalStateInterface): boolean => state.loading,
  [GlobalGetters.getDialog]: (state: GlobalStateInterface): DialogInterface => state.dialog,
  [GlobalGetters.getSnackbar]: (state: GlobalStateInterface): SnackbarInterface | null => state.snackbar,
}
