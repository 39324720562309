import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import beforeEach from '@/shared/helpers/before-each.helper'
import { FilterOperator } from '@/shared/classes/data-table/data-table-filter'
import { RuleType } from '@/shared/configs/rule-types'

Vue.use(VueRouter)

export enum Routes {
  app = 'app',
  calculators = 'calculators',
  calculatorsShow = 'calculators-show',
  calculatorParameters = 'calculators-parameters',
  calculatorDataTables = 'calculators-data-tables',
  calculatorRules = 'calculators-rules',
  calculatorRulesAll = 'calculators-rules-all',
  calculatorRulesNotes = 'calculators-rules-notes',
  calculatorRulesRefers = 'calculators-rules-refers',
  calculatorRulesDeclines = 'calculators-rules-declines',
  calculatorCalculationSteps = 'calculators-calculation-steps',
  calculatorVersionHistory = 'calculators-version-history',
  calculatorTestSets = 'calculators-test-sets',
  calculatorLogs = 'calculators-logs',
  calculatorLogsAll = 'calculators-logs-all',
  calculatorLogsSuccess = 'calculators-logs-success',
  calculatorLogsFailure = 'calculators-logs-failure',
  profile = 'profile',
  profileCalculationLogs = 'profile-calculation-logs',
  profileCalculationLogsAll = 'profile-calculation-logs-all',
  profileCalculationLogsSuccess = 'profile-calculation-logs-success',
  profileCalculationLogsFailure = 'profile-calculation-logs-failure',
  login = 'login',
  remindPassword = 'remind-password',
  resetPassword = 'reset-password',
  setPassword = 'set-password',
  notFound = 'not-found',
  users = 'users',
  clients = 'clients',
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: Routes.app,
    component: () => import('@/layouts/AppLayout.vue'),
    redirect: { name: Routes.calculators },
    children: [
      {
        name: Routes.calculators,
        path: 'calculators',
        component: () => import('@/views/Calculators.vue'),
      },
      {
        path: 'calculators/:uuid',
        name: Routes.calculatorsShow,
        component: () => import('@/layouts/CalculatorInnerLayout.vue'),
        redirect: { name: Routes.calculatorParameters },
        children: [
          {
            path: 'parameters',
            name: Routes.calculatorParameters,
            component: () => import('@/views/calculators/Parameters.vue'),
          },
          {
            path: 'data-tables',
            name: Routes.calculatorDataTables,
            component: () => import('@/views/calculators/DataTables.vue'),
          },
          {
            path: 'rules',
            name: Routes.calculatorRules,
            component: () => import('@/views/calculators/Rules.vue'),
            redirect: { name: Routes.calculatorRulesAll },
            children: [
              {
                path: 'all',
                name: Routes.calculatorRulesAll,
                component: () => import('@/views/calculators/Rules.vue'),
              },
              {
                path: 'notes',
                name: Routes.calculatorRulesNotes,
                component: () => import('@/views/calculators/Rules.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: RuleType.note,
                    key: 'type',
                  }
                }
              },
              {
                path: 'refers',
                name: Routes.calculatorRulesRefers,
                component: () => import('@/views/calculators/Rules.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: RuleType.refer,
                    key: 'type',
                  }
                }
              },
              {
                path: 'declines',
                name: Routes.calculatorRulesDeclines,
                component: () => import('@/views/calculators/Rules.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: RuleType.decline,
                    key: 'type',
                  }
                }
              }
            ]
          },
          {
            path: 'calculation-steps',
            name: Routes.calculatorCalculationSteps,
            component: () => import('@/views/calculators/CalculationSteps.vue'),
          },
          {
            path: 'version-history',
            name: Routes.calculatorVersionHistory,
            component: () => import('@/views/calculators/VersionHistory.vue'),
          },
          {
            path: 'test-sets',
            name: Routes.calculatorTestSets,
            component: () => import('@/views/calculators/TestSets.vue'),
          },
          {
            path: 'logs',
            name: Routes.calculatorLogs,
            component: () => import('@/views/calculators/CalculatorLogs.vue'),
            redirect: { name: Routes.calculatorLogsAll },
            children: [
              {
                path: 'all',
                name: Routes.calculatorLogsAll,
                component: () => import('@/views/calculators/CalculatorLogs.vue'),
              },
              {
                path: 'success',
                name: Routes.calculatorLogsSuccess,
                component: () => import('@/views/calculators/CalculatorLogs.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: true,
                    key: 'success',
                  }
                }
              },
              {
                path: 'failure',
                name: Routes.calculatorLogsFailure,
                component: () => import('@/views/calculators/CalculatorLogs.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: false,
                    key: 'success',
                  }
                }
              }
            ]
          },
        ]
      },
      {
        path: '/profile',
        name: Routes.profile,
        component: () => import('@/layouts/ProfileInnerLayout.vue'),
        redirect: { name: Routes.profileCalculationLogs },
        children: [
          {
            path: 'logs',
            name: Routes.profileCalculationLogs,
            component: () => import('@/views/Logs.vue'),
            redirect: { name: Routes.profileCalculationLogsAll },
            children: [
              {
                path: 'all',
                name: Routes.profileCalculationLogsAll,
                component: () => import('@/views/Logs.vue'),
              },
              {
                path: 'success',
                name: Routes.profileCalculationLogsSuccess,
                component: () => import('@/views/Logs.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: true,
                    key: 'success',
                  }
                }
              },
              {
                path: 'failure',
                name: Routes.profileCalculationLogsFailure,
                component: () => import('@/views/Logs.vue'),
                meta: {
                  filter: {
                    operator: FilterOperator.equals,
                    value: false,
                    key: 'success',
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: '/login',
        name: Routes.login,
        components: {
          auth: () => import('@/views/Login.vue'),
        },
        meta: {
          notRedirectApp: true
        }
      },
      {
        path: '/remind-password',
        name: Routes.remindPassword,
        components: {
          auth: () => import('@/views/RemindPassword.vue'),
        },
        meta: {
          notRedirectApp: true,
        }
      },
      {
        path: '/reset-password/:token/:email',
        name: Routes.resetPassword,
        components: {
          auth: () => import('@/views/ResetPassword.vue'),
        },
        meta: {
          notRedirectApp: true,
        }
      },
      {
        path: '/set-password/:token/:email',
        name: Routes.setPassword,
        components: {
          auth: () => import('@/views/SetPassword.vue'),
        },
        meta: {
          notRedirectApp: true,
        }
      },
      {
        path: '/users',
        name: Routes.users,
        component: () => import('@/views/Users.vue'),
        meta: {
          notRedirectApp: true
        }
      },
      {
        path: '/clients',
        name: Routes.clients,
        component: () => import('@/views/Clients.vue'),
        meta: {
          notRedirectApp: true
        }
      },
      {
        path: '*',
        name: Routes.notFound,
        components: {
          auth: () => import('@/views/NotFound.vue'),
          default: () => import('@/views/NotFound.vue'),
        },
        redirect: { name: Routes.app }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(beforeEach)

export default router
