import state from '@/shared/store/auth/auth.state'
import getters from '@/shared/store/auth/auth.getters'
import mutations from '@/shared/store/auth/auth.mutations'
import actions from '@/shared/store/auth/auth.actions'

export default {
  state,
  getters,
  mutations,
  actions,
}
