import Field from '@/shared/classes/form/field'

export enum FilterOperator {
  equals = 'equals',
  like = 'like',
  in = 'in',
  notIn = 'notIn',
  nulled = 'nulled',
  gte = 'gte',
  lte = 'lte'
}

export default class DataTableFilter {
  field!: Field
  title!: string
  operator: FilterOperator = FilterOperator.equals

  setField(field: Field): this {
    this.field = field

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setOperator(operator: FilterOperator): this {
    this.operator = operator

    return this
  }
}
