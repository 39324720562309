import { ActionContext } from 'vuex'
import { AuthMutations } from '@/shared/store/auth/auth.mutations'
import http from '@/shared/http'
import tokenHelper from '@/shared/helpers/token.helper'
import User from '@/shared/modules/user/user'
import ResponseInterface from '@/shared/interfaces/response.interface'
import { AuthStateInterface } from '@/shared/store/auth/auth.state'

export enum AuthActions {
  me = 'AUTH/ME',
  logout = 'AUTH/LOGOUT',
}

export default {
  [AuthActions.me]: async ({ commit }: ActionContext<AuthStateInterface, any>) => {
    commit(AuthMutations.setLoading, true)
    await http.get('me')
      .then((response: ResponseInterface<User>) => {
        commit(AuthMutations.setUser, response.data)
      })
      .catch(() => {
        tokenHelper.clearToken()
        commit(AuthMutations.reset)
      })
      .finally(() => commit(AuthMutations.setLoading, false))
  },
  [AuthActions.logout]: ({ commit, state }: ActionContext<AuthStateInterface, any>) => {
    if (! state.user) {
      tokenHelper.clearToken()
      commit(AuthMutations.reset)
      return
    }

    return http.post('logout')
      .then(() => commit(AuthMutations.reset))
      .catch(() => {
        tokenHelper.clearToken()
        commit(AuthMutations.reset)
      })
  }
}
