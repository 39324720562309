import { ActionContext } from 'vuex'
import GlobalStateInterface from '@/shared/store/global/global.interface'
import { GlobalMutations } from '@/shared/store/global/global.mutations'
import { DialogInterface } from '@/shared/interfaces/dialog.interface'
import SnackbarInterface from '@/shared/interfaces/snackbar.interface'

export enum GlobalActions {
  showLoading = 'GLOBAL/SHOW_LOADING',
  showDialog = 'GLOBAL/SHOW_DIALOG',
  closeDialog = 'GLOBAL/CLOSE_DIALOG',
  showSnackbar = 'GLOBAL/SHOW_SNACKBAR',
}

export default {
  [GlobalActions.showLoading]: ({ commit }: ActionContext<GlobalStateInterface, any>, payload: boolean): void => commit(GlobalMutations.setLoading, payload),
  [GlobalActions.showDialog]: ({ commit }: ActionContext<GlobalStateInterface, any>, payload: DialogInterface): void => {
    payload.show = true
    commit(GlobalMutations.setDialog, payload)
  },
  [GlobalActions.closeDialog]: ({ commit }: ActionContext<GlobalStateInterface, any>): void => commit(GlobalMutations.resetDialog),
  [GlobalActions.showSnackbar]: ({ commit }: ActionContext<GlobalStateInterface, any>, payload: SnackbarInterface | null): void => commit(GlobalMutations.setSnackbar, payload),
}
